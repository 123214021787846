import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider, useRoutes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Logout from "./components/Logout";



const router = createBrowserRouter([
 
  {
    path: "/*",
    element: <App />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
    <RouterProvider router={router} />
);

reportWebVitals();
