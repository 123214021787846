// Chat.js
import React, { useEffect, useState } from 'react';
import SideBar from './SideBar';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
import '../chat.css';
import axios from 'axios';
import { Typography, TextField, Button, Box } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

const Chat = ({messageHistory, selectedChatId, userThreads}) => {
  const hostname = process.env.REACT_APP_BASE_URL
  const mockUserData =  {

  }

  const [messages, setMessages] = useState(messageHistory);
  const [noThreads, setNoThreads] = useState(false);
  const [assistant, setAssistant] = useState("asst_QKyb08PHZVGxkeV6jzrf1Rjm");
  const [loading, setLoading] = useState(false); // Add loading state
  const [showForm, setShowForm] = useState(true); // Add showForm state
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    setMessages(messageHistory);
  }, [messageHistory]);

  const handleSendMessage = async (newMessage) => {
    if (newMessage.trim()) {
      const newMsg = { text: newMessage, isUser: true };
      setMessages((prevMessages) => [...prevMessages, newMsg]);
    
      // If you're using a backend service to store messages, you should send the message to the backend here as well
      setLoading(true); // Set loading state to true
      await axios.post(`${hostname}/api/send-message`, {threadId: selectedChatId, content: newMessage, assistant: assistant });
      handleGetResponse(newMsg);
    }
  };
  
  const handleGetResponse = async (newMsg) => {
    const getResponse = async () => {
      const response = await axios.get(`${hostname}/api/get-response`, { params: { threadId: selectedChatId } });
      const responseText = response.data.response;
      const botMsg = { text: responseText, isUser: false };
      
      setMessages((prevMessages) => [...prevMessages, botMsg]);
      setLoading(false); // Set loading state to false
    }
    getResponse();
  };

  useEffect(() => {
    if (userThreads.length === 0 ) {
      setNoThreads(true);
    }
    if (selectedChatId === null) {
      setNoThreads(true);
    } else if(userThreads.length === 1) {
      setNoThreads(false);
      setMessages(messageHistory);
    }
  }, [userThreads, selectedChatId, messageHistory]);

    const handleSubmitForm = async (e)  =>  {
      setLoading(true);
      e.preventDefault();
try{ 
await axios.post(`${hostname}/api/create-lead`, {data:{ email, firstName, lastName} });
setShowForm(false);
document.cookie = "msiChatApp=true"; 
setLoading(false);
}catch(err){
setShowForm(false);
document.cookie = "msiChatApp=true"; 
setLoading(false);
}
     
    };

    return (
      <div className="chat-container">
        {(!document.cookie.includes("msiChatApp=true") && showForm) ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            <Typography align="center">
              Enter your name and  <br /> your email address
            </Typography>
            <form onSubmit={handleSubmitForm} style={{ textAlign: 'center'}}>
              
              <TextField
                label="First Name"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                variant="standard"
                margin="normal"
                style={{ padding: '10px' }} // Add padding
              />
              <TextField
                label="Last Name"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                variant="standard"
                margin="normal"
                style={{ padding: '10px' }} // Add padding
              />
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                variant="standard"
                margin="normal"
                style={{ padding: '10px' }} // Add padding
              />
              <div style={{ marginTop: 'auto', padding: '10px' }}> {/* Add marginTop: 'auto' to push the button to the bottom */}
                <Button type="submit" variant="contained" color="primary">
                  Chat with us
                </Button>
                {loading && <div className='loader'></div>}
              </div>
            </form>
          </Box>
        ) : (
          <>
            {noThreads && null}
            {loading && <div className='loader'></div>}
            {!noThreads ? <MessageList messages={messages}/> : null} 
            {!noThreads ? <ChatInput onSendMessage={handleSendMessage} /> : null }
          </>
        )}
      </div>
    );
  };

export default Chat;
