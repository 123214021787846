// ChatApp.js
import React, { useEffect, useState } from 'react';
import Sidebar from './components/SideBar';
import Chat from './components/Chat'; // This is the main chat component that includes MessageList and ChatInput
import './chat.css'; // Ensure the CSS is imported here
import axios from 'axios';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const hostname = process.env.REACT_APP_BASE_URL

const ChatApp = () => {
const { isAuthenticated, user } = useAuth0();
const [userThreads, setUserThreads] = useState([]);

  const [chats, setChats] = useState([]);


  const [selectedChatId, setSelectedChatId] = useState();
  const [messages, setMessages] = useState([{
    "text": "Hello! Meet our AI Assistant, you can ask him any cybersecurity questions you have. Please note the session is not monitored and our AI Assistant might give you some unexpected answers (he is still in training mode)",
    "isUser": false}]); // You would fetch messages based on the selected chat
  // const [userThreads, setUserThreads] = useState([]);
  useEffect(  ()  =>  {
    const createThread = async () => {
      const response = await axios.get(`${hostname}/api/create-thread`);
      const threadId = response.data.thread;
      setUserThreads([threadId]);
      setSelectedChatId(threadId.id);
    }
    createThread();
  }, []);



  useEffect(() => {
 
  }, [userThreads]);



 
  return (
    <div className="chat-app-container">
      <Chat messageHistory={messages} selectedChatId={selectedChatId} userThreads={userThreads} /> 
    </div>
  );
};

export default ChatApp;
