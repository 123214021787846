// ChatMessage.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom'; // Import the Link component
import '../chat.css';

const ChatMessage = ({ message, isUser }) => {
  const messageClass = isUser ? 'message user' : 'message bot';

  // Regular expression to find URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Function to replace URLs with clickable links in markdown format
  const replaceURLs = (text) => {
    return text.replace(urlRegex, (url) => {
      return `[${url}](${url})`;
    });
  };

  const processedMessage = replaceURLs(message);
  const reProcessedMessage = processedMessage.replace(/【.*?】/g, '');
  
  function LinkRenderer(props) {
    return <a href={props.href} target="_blank">{props.children}</a>
  }
  
  

  return (
    <div className={messageClass}>
      <ReactMarkdown className="message-content" components={{a: LinkRenderer}}>
        {reProcessedMessage}
      </ReactMarkdown>
    </div>
  );
};

export default ChatMessage;